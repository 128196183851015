<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import draggable from "vuedraggable";
import { Chrome } from 'vue-color'
export default {
  name: "BoHome",
  extends: Base,
  components: {
    draggable,
    'chrome-picker': Chrome
  },
  data() {
    return {
      Name: "BoHome",
      data: [],
      slideshow: {
        as_val: {
          autoplay: "",
          delay: 1
        }
      },
      row: {},
      input: {},
      nilai: [],
      about: {},
      prestasi: [],
      aprImg: [],
      aprImgInput: [],
      kurikulum: {},
      kurikulumImage: {
        currImg: "",
        currAltImg: "",
        currLabel: "",
        currLabelAlt: "",
        curLabelInput: "",
      },
      ObjectName: "Slideshow",
      fasilitas: [],
      fasilitasRaw: {},
      testi: {},
      article: {},
      isSlideEdit: false,
      isNilaiEdit: false,
      isFasilitasEdit: false,
      xxsmaxlength: 180,
      xsmaxlength: 200,
      smmaxlength: 500,
      mdmaxlength: 600,
      lgmaxlength: 900,
      colors: "#194d33",
      isPickerTitle: false,
      isPickerDesc: false,
      isSlideButton: "N",
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() => {
      setTimeout(() => {
        this.$set(this.row, "type", "update");
      }, 500);
    });
  },
  computed: {
    isSlideComputed: {
      get() {
        return this.row.as_is_button || this.isSlideButton
      }
    },
    inputColorTitle: {
      get() {
        return this.row.as_color_title;
      },
      set(newValue) {
        if (!newValue) {
          newValue = "#000000";
          this.row.as_color_title = newValue;
        }
        this.row.as_color_title = newValue;
      }
    },
    inputColorDescription: {
      get() {
        return this.row.as_color_description;
      },
      set(newValue) {
        if (!newValue) {
          newValue = "#000000";
          this.row.as_color_description = newValue;
        }
        this.row.as_color_description = newValue;
      }
    },
    pickerTitle: {
      get() {
        return this.row.as_color_title;
      },
      set(newValue) {
        this.row.as_color_title = newValue.hex;
      }
    },
    pickerDesc: {
      get() {
        return this.row.as_color_description;
      },
      set(newValue) {
        this.row.as_color_description = newValue.hex;
      }
    },
    sliderDesc: {
      get() {
        return this.row.as_desc;
      },
      set(newValue) {
        $("[error='slider_desc']").html(
          this.xxsmaxlength - newValue.length < 0
            ? `<small class='error' id="sliderDesc">Input max ${this.xxsmaxlength} characters</small>`
            : `<small class="mb-0 text-info">${this.xxsmaxlength - newValue.length} characters left</small>`
        );
        this.row.as_desc = newValue;
      },
    },
    aboutDesc: {
      get() {
        return this.about.as_val.desc;
      },
      set(newValue) {
        const dom = document.createElement('div');
        dom.innerHTML = newValue;
        const content = dom.textContent
                          .replaceAll('\n', '')
                          .replace(/\s{2,}/g, ' ')
                          .trim();
        $("[error='about_desc']").html(
          this.lgmaxlength - content.length < 0
          ? `<small class='error'>Input max ${this.lgmaxlength} characters</small>`
          : `<small class="mb-0 text-info">${this.lgmaxlength - content.length} characters left</small>`
        );
        this.about.as_val.desc = newValue;
      },
    },
    prestasiDesc: {
      get() {
        return this.prestasi.as_val.desc;
      },
      set(newValue) {
        $("[error='prestasi_desc']").html(
          this.mdmaxlength - newValue.length < 0
            ? `<small class='error'>Input max ${this.mdmaxlength} characters</small>`
            : `<small class="mb-0 text-info">${this.mdmaxlength - newValue.length} characters left</small>`
        );
        this.prestasi.as_val.desc = newValue;
      },
    },
    nilaiDesc: {
      get() {
        return this.input.an_desc;
      },
      set(newValue) {
        $("[error='nilai_desc']").html(
          this.xsmaxlength - newValue.length < 0
            ? `<small class='error'>Input max ${this.xsmaxlength} characters</small>`
            : `<small class="mb-0 text-info">${this.xsmaxlength - newValue.length} characters left</small>`
        );
        this.input.an_desc = newValue;
      },
    },
    kurikulumDesc: {
      get() {
        return (this.kurikulum.as_val || {}).desc_content;
      },
      set(newValue) {
        const dom = document.createElement('div');
        dom.innerHTML = newValue;
        const content = dom.textContent
                          .replaceAll('\n', '')
                          .replace(/\s{2,}/g, ' ')
                          .trim();
        $("[error='kurikulum_desc']").html(
          this.smmaxlength - content.length < 0
            ? `<small class='error'>Input max ${this.smmaxlength} characters</small>`
            : `<small class="mb-0 text-info">${this.smmaxlength - content.length} characters left</small>`
        );
        this.kurikulum.as_val.desc_content = newValue;
      },
    },
  },
  methods: {
    isEmptyObject(value) {
      return jQuery.isEmptyObject(value);
    },
    showPicker(state="") {
      switch(state) {
        case 'isPickerTitle':
          this.isPickerTitle = true;
          break;
        case 'isPickerDesc':
          this.isPickerDesc = true;
          break;
        default:
          this.isPickerTitle = false;
          this.isPickerDesc = false;
          break;
      }
    },
    submitHero() {
      BOGen.apirest(
        "/" + this.Name,
        this.row,
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info");
            this.refreshData();
            setTimeout(() => {
              $(".c-slide").hide();
            }, 3500);
          }
        },
        "POST"
      );
    },
    submitGoodness(e) {
      if (e && e.btnLoading()) return;
      if(this.input.an_img){
        $("[error='an_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.input,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subNilai").html(this.isNilaiEdit ? "Ubah" : "Tambah");
          if (err){
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
              $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $(".c-nilai").offset().top - 150,
                  },
                  500
              );
            }
            return;
          }
          if (resp.error)
            this.refreshData();  
            Gen.info(
              resp.message,
              "danger",
              2000,
              ".col-info"
            );
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".col-info");
            this.refreshData();
            setTimeout(() => {
              $(".c-nilai").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    editAbout() {
      $('#clTentangKami').slideToggle();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#clTentangKami").offset().top - 150,
        },
        500
      );
    },
    submitFormSlider(e, callback) {
      if (e && e.btnLoading()) return;
      BOGen.apirest(
        "/" + this.Name,
        this.row,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subSlideShow").html(this.isSlideEdit ? "Ubah" : "Tambah");
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  var textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            this.refreshData();
            return Gen.info(`${resp.message}, ${resp.desc}`, "danger", 2000);
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $('[href="#collapseExample"]').click();
              this.refreshData();
              $(".c-slide").hide();
            }, 1500);
            return Gen.info(resp.message, "success", 1500).then(() => {
              this.$router.push({ name: this.Name });
            });
          }
        },
        "POST"
      );
    },
    submitAbout(e) {
      if (e && e.btnLoading()) return;

      if(this.about.as_val.alt_img.length){
        $("[error='alt_img']").html("");
      }
      this.about.type = "updateAbout";
      BOGen.apirest(
        "/" + this.Name,
        this.about,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<span class='error'>" + textError + "</span>"
                );
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#collapseAbout").offset().top - 150,
                  },
                  500
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".about-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".about-info");
            setTimeout(() => {
              $('#clTentangKami').slideToggle();
              $([document.documentElement, document.body]).animate(
              {
                scrollTop: $("#collapseAbout").offset().top - 800,
              },
              500
            );
            }, 2000);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    toggleShow(as_id, e) {
      const show = e.target.checked ? "Y" : "N";
      swal({
        title: `Apakah anda yakin untuk ${ e.target.checked ? 'menampilkan' : 'menyembunyikan' } section ini?`,
        text: "",
        icon: "warning",
        buttons: ["Tidak, Kembali!", `Ya, ${e.target.checked ? 'Tampilkan' : 'Sembunyikan'}!`],
        dangerMode: false,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            {
              type: "toggleShow",
              as_id,
              show
            },
            (err, resp) => {
              if (resp.success) {
                swal(resp.message, "", "success");
                this.refreshData();
              }
            },
            "POST"
          );
        }
      });
    },
    toggleAutoplay(e) {
      const autoplay = e.target.checked ? "Y" : "N";
      swal({
        title: `Apakah anda yakin untuk ${ e.target.checked ? 'mengaktifkan' : 'menonaktifkan' } fitur ini?`,
        text: "",
        icon: "warning",
        buttons: ["Tidak, Kembali!", `Ya, ${e.target.checked ? 'Aktikan' : 'Nonaktifkan'}!`],
        dangerMode: false,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            {
              type: "toggleAutoplay",
              autoplay
            },
            (err, resp) => {
              if (resp.success) {
                swal(resp.message, "", "success");
                this.refreshData();
              }
            },
            "POST"
          );
        }
      });
    },
    updateDelay() {
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateDelay",
          delay: this.slideshow.as_val.delay
        },
        (err, resp) => {
          if (resp.success) {
            swal(resp.message, "", "success");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitKurikulum(e) {
      if (e && e.btnLoading()) return;
      this.kurikulum.type = "updateKurikulum";

      BOGen.apirest(
        "/" + this.Name,
        this.kurikulum,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<span class='error'>" + textError + "</span>"
                );
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#clCurriculumContent").offset().top - 100,
                  },
                  500
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kurikulum-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kurikulum-info");
            this.refreshData();
            setTimeout(() => {
              $("#clCurriculumContent").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitKurikulumImage(e) {
      if (e && e.btnLoading()) return;
      this.kurikulumImage.type = "updateKurikulumImage";
      BOGen.apirest(
        "/" + this.Name,
        this.kurikulumImage,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }
          if (resp.error) return swal(resp.message, resp.desc || "", "error");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            $("#modalCurriculumImg").modal("hide");
            swal("Berhasil memperbarui foto kurikulum", "", "success");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    editPrestasi() {
      $('#clFormPrestasi').slideToggle();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#clFormPrestasi").offset().top - 150,
        },
        500
      );
    },
    submitImgPrestasi(e) {
      if (e && e.btnLoading()) return;
      BOGen.apirest(
        "/" + this.Name,
        this.aprImgInput,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".prestasi-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".prestasi-info");
            this.refreshData();
            setTimeout(() => {
              $("#collapseImage").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitFasilitas(e) {
      if (e && e.btnLoading()) return;
      if(this.fasilitasRaw.ap_img){
        $("[error='ap_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.fasilitasRaw,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#collapseAbout").offset().top - 150,
                  },
                  500
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".fasilitas-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".fasilitas-info");
            this.refreshData();
            setTimeout(() => {
              $("#collapseFasilitas").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitShowTestimoni(e) {
      if (e && e.btnLoading()) return;
      this.testi.type = "testi";
      BOGen.apirest(
        "/" + this.Name,
        this.testi,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".testi-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".testi-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitArticle(e) {
      if (e && e.btnLoading()) return;
      this.article.type = "article";
      BOGen.apirest(
        "/" + this.Name,
        this.article,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".article-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".article-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    addImage() {
      $("#collapseImage").slideToggle();
      this.aprImgInput = {};
      this.aprImgInput = {
        ap_is_active: "Y",
      };
      this.aprImgInput.type = "addImagePrestasi";
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapseImage").offset().top,
        },
        500
      );
    },
    editImage(v) {
      $("#collapseImage").slideToggle();
      this.aprImgInput = v;
      this.aprImgInput.type = "updateImagePrestasi";
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapseImage").offset().top,
        },
        500
      );
    },
    addSlide() {
      this.isSlideEdit = false;
      $("#subSlideshow").html('Tambah')
      $("label.error").css("display","none");
      $(".form-control").removeClass("error");
      $(".text-info").hide()
      
      let disp = $("#collapseExample").css("display")
      if(!disp){
        $(".c-slide").css("display","block")
      }
      $("#collapseExample").slideToggle();
      
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseExample").offset().top - 150,
          },
          500
        );
      },100)
      this.row = {};
      this.row = {
        as_is_active: "Y",
        as_is_button: "N",
        as_link_target: "_self",
        as_title: "",
        as_desc: "",
        as_link: "",
        as_color_title: "#000000",
        as_color_description: "#000000",
      };
      this.row.type = "add";
    },
    editSlide(v) {
      this.isSlideEdit = true;
      $("#subSlideshow").html('Ubah')
      $("label.error").css("display","none");
      $(".text-info").hide()
      $(".form-control").removeClass("error");
      
      let disp = $("#collapseExample" + v.as_id).css("display")
      if(!disp){
        $(".c-slide").css("display","block")
      }
      $("#collapseExample"+v.as_id).slideToggle();
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseExample"+v.as_id).offset().top - 150,
          },
          500
        );
      },100)
      this.row = v;
      this.row.type = "update";
      this.row.as_link_target =
        this.row.as_link_target == "" ? "_self" : this.row.as_link_target;
    },
    addNilai() {
      this.isNilaiEdit = false;
      $("#subNilai").html('Tambah')
      $("label.error").css("display","none");
      $(".text-info").hide()
      
      let disp = $("#collapseNilai").css("display")
      if(!disp){
        $(".c-nilai").css("display","block")
      }
      
      $("#collapseNilai").slideToggle();
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseNilai").offset().top - 150,
          },
          500
        );
      },100)
      this.input = {};
      this.input = {
        an_is_active: "Y",
        an_title: "",
        an_bg: "bg-yellow",
        an_desc: "",
        an_img: "",
        an_alt_img: ""
      };
      this.input.type = "addNilai";
    },
    editNilai(v) {
      this.isNilaiEdit = true;
      $("#subNilai").html('Ubah')
      $("label.error").css("display","none");
      $(".text-info").hide()

      let disp = $("#collapseNilai" + v.an_id).css("display")
      if(!disp){
        $(".c-nilai").css("display","block")
      }

      $("#collapseNilai" + v.an_id).slideToggle();
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseNilai" + v.an_id).offset().top - 150,
          },
          500
        );
      },100)
      this.input = v;
      this.input.type = "updateNilai";
    },
    addFasilitas() {
      this.isFasilitasEdit = false;
      $("#collapseFasilitas").slideToggle();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapseFasilitas").offset().top,
        },
        500
      );
      this.fasilitasRaw = {};
      this.fasilitasRaw = {
        ap_is_active: "Y",
      };
      this.fasilitasRaw.type = "addFasilitas";
    },
    editFasilitas(v) {
      this.isFasilitasEdit = true;
      $("#collapseFasilitas").slideToggle();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapseFasilitas").offset().top,
        },
        500
      );
      this.fasilitasRaw = v;
      this.fasilitasRaw.type = "updateFasilitas";
    },
    editCrlImg(img, alt, label, labelAlt) {
      $("#modalCurriculumImg").modal();
      this.kurikulumImage.currImg = img;
      this.kurikulumImage.currAltImg = alt;
      this.kurikulumImage.currLabelAlt = labelAlt;
      this.kurikulumImage.currLabel = label
      .split(" #")
        .join("")
        .toLowerCase();
      this.kurikulumImage.curLabelInput = label;
    },
    endDrag() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data,
          type: "sort",
        },
        (err, resp) => {
          this.refreshData()
          $(".c-slide").hide();          
        },
        "POST"
      );
    },
    endDragPrestasi(data) {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: data,
          type: "sortPrestasi",
        },
        (err, resp) => {},
        "POST"
      );
    },
    endDragNilai(data) {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: data,
          type: "sortNilai",
        },
        (err, resp) => {
          this.refreshData()
          $(".c-nilai").hide();          
        },
        "POST"
      );
    },
    scrollTo(el) {
      $(el).slideToggle();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(el).offset().top - 150,
        },
        500
      );
    },
  },
  watch: {
    "$route.query"() {
      this.refreshData();
    },
    "about.as_val.title"(v){
      this.about.as_val.alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='title'] span").text("Input min 3 characters");
        } else {
          $("[error='title']").html("");
        }
        $("[error='alt_img']").html($("[error='title']").html());
      }
    },
    "about.as_val.desc"() {
      let plain = $("<div/>").html(CKEDITOR.instances.desc.getSnapshot()).text();
      if (plain.length > 0) {
        if (plain.length < 10) {
          $("[error='desc'] span").text("Input min 10 characters");
        } else {
          $("[error='desc']").html("");
        }
      }
    },
    "row.as_title"(v) {
      this.slTitleLength = v.length;
      this.row.as_alt_img = v;
      this.row.as_alt_img_mobile = v;
      if (v.length > 0) {
        setTimeout(() => {
          $("[name='as_alt_img']").valid()
          $("[name='as_alt_img_mobile']").valid()
        }, 100);
      }
    },
    "input.an_title"(v) {
      this.input.an_alt_img = v;
      if (v.length > 0) {
        $("[name='an_title']").valid();
        $("[error='an_alt_img']").html($("[error='an_title']").html());
      } else {
        $("[name='an_title']").valid();
        $("[error='an_alt_img']").html($("[error='an_title']").html());
      }
    },
    "input.an_desc"(v) {
      $("[name='an_desc']").valid();
    },
    "input.an_img"(v) {
      if (v.length > 0) {
        $("[error='an_img']").html("");
      }
    },
    "fasilitasRaw.ap_name"(v) {
      this.fasilitasRaw.ap_alt_img = v;
    },
    "slideshow.as_val.delay"(v) {
      if (v) {
        if (v < 1) {
          this.slideshow.as_val.delay = 1;
        } else if (v.length > 3) {
          this.slideshow.as_val.delay = v.slice(0, 3);
        }
      }
    },
    "kurikulum.as_val.title"(v) {
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='titleKurikulum'] span").text("Input min 3 characters");
        } else {
          $("[error='titleKurikulum']").html("");
        }
      }
    },
    "kurikulum.as_val.desc_content"() {
      let plain = $("<div/>").html(CKEDITOR.instances.desc_content.getSnapshot()).text();
      if (plain.length > 0) {
        if (plain.length < 10) {
          $("[error='desc_content'] span").text("Input min 10 characters");
        } else {
          $("[error='desc_content']").html("");
        }
      }
    },
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="picker-overlay" @click="showPicker()" v-if="isPickerTitle || isPickerDesc"></div>
    
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Slide Show</h5>
      </div>

      <div class="card-body">
        <div class="d-flex align-items-center">
          <div v-if="moduleRole('Edit')" class="form-group row align-items-center mb-0">

            <div class="form-group mb-0 mr-3 ml-2">
              <div class="custom-control custom-checkbox">
                <input
                  class="custom-control-input"
                  :checked="slideshow.as_val.autoplay === 'Y'"
                  @click.prevent="toggleAutoplay($event)"
                  id="slideShowCheck"
                  type="checkbox"
                />
                <label class="custom-control-label" for="slideShowCheck"
                  >Aktifkan geser otomatis</label
                >
              </div>
            </div>

            <div class="col-md-4">
              <div class="input-group">
                <input type="number" min="1" v-model="slideshow.as_val.delay" @change="updateDelay" class="form-control">
                <div class="input-group-append">
                  <span class="input-group-text">Detik</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <draggable
          v-if="moduleRole('Edit')"
          v-model="data"
          class="row gutter-20"
          tag="tbody"
          @end="endDrag"
        >
          <div
            style="cursor:move;"
            class="col-lg-3 col-md-4"
            :id="'dat' + v.as_id"
            v-for="(v, k) in data"
            :key="k"
          >
            <div class="wrap_slider_img">
              <img :src="uploader(v.as_img)" class="img-responsive" />

              <div class="slider_name">
                <p>{{ v.as_title }}</p>
              </div>

              <div class="bullet-cta">
                <a
                  v-if="moduleRole('Edit')"
                  data-toggle="collapse"
                  @click="editSlide(v)"
                  href="javascript:;"
                  class="bullet_edit"
                  v-tooltip="'Ubah'"
                  ><i class="ti-marker-alt"></i
                ></a>
                <a
                  v-if="moduleRole('Edit')"
                  href="javascript:;"
                  class="bullet_change_status bg-warning"
                  @click="
                    changeStatusDynamic(v, 'as_is_active', 'as_id', 'AppSlider', undefined, `collapseExample${isSlideEdit ? v.as_id : ''}`)
                  "
                  v-tooltip="'Ubah Status'"
                  ><i class="ti-settings"></i
                ></a>
                <a
                  v-if="moduleRole('Delete')"
                  href="javascript:;"
                  @click="
                    deleteSlideShow(v.as_id, 'dat' + v.as_id, 'slideshow', `collapseExample${isSlideEdit ? v.as_id : ''}`)
                  "
                  class="bullet_delete"
                  v-tooltip="'Hapus'"
                  ><i class="far fa-trash-alt"></i
                ></a>
              </div>

              <span class="label label-success" v-if="v.as_is_active == 'Y'"
                >Active</span
              >

              <span class="label label-danger" v-else>Inactive</span>
          
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <a
              v-if="moduleRole('Add')"
              class="wrap_upload"
              data-toggle="collapse"
              @click="addSlide"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="ic_wrap">
                <i class="fas fa-plus"></i>
                <p>Tambah Slide Show</p>
              </div>
            </a>
          </div>

        </draggable>

        <div v-else class="row gutter-20" tag="tbody" @end="endDrag">
          <div
            class="col-lg-3 col-md-4"
            :id="'dat' + v.as_id"
            v-for="(v, k) in data"
            :key="k"
          >
            <div class="wrap_slider_img">
              <img :src="uploader(v.as_img)" class="img-responsive" />

              <div class="slider_name">
                <p>{{ v.as_title }}</p>
              </div>

              <div class="bullet-cta">
                <a
                  v-if="moduleRole('Edit')"
                  data-toggle="collapse"
                  @click="editSlide(v)"
                  href="javascript:;"
                  class="bullet_edit"
                  v-tooltip="'Ubah'"
                  ><i class="ti-marker-alt"></i
                ></a>
                <a
                  v-if="moduleRole('Edit')"
                  href="javascript:;"
                  class="bullet_change_status bg-warning"
                  @click="
                    changeStatusDynamic(v, 'as_is_active', 'as_id', 'AppSlider')
                  "
                  v-tooltip="'Ubah Status'"
                  ><i class="ti-settings"></i
                ></a>
                <a
                  v-if="moduleRole('Delete')"
                  href="javascript:;"
                  @click="
                    deleteSlideShow(v.as_id, 'dat' + v.as_id, 'slideshow')
                  "
                  class="bullet_delete"
                  v-tooltip="'Hapus'"
                  ><i class="far fa-trash-alt"></i
                ></a>
              </div>

              <span class="label label-success" v-if="v.as_is_active == 'Y'"
                >Active</span
              >

              <span class="label label-danger" v-else>Inactive</span>

            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <a
              v-if="moduleRole('Add')"
              class="wrap_upload"
              data-toggle="collapse"
              @click="addSlide"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >

              <div class="ic_wrap">
                <i class="fas fa-plus"></i>
                <p>Tambah Slide Show</p>
              </div>

            </a>
          </div>
        </div>
        <div
          v-if="isSlideEdit ? moduleRole('Edit') : moduleRole('Add')"
          class="card border collapse my-3 c-slide"
          :id="isSlideEdit ? 'collapseExample'+ row.as_id : 'collapseExample'"
        >
          <div class="card-header">
            <h5 class="card-title mb-3">
              {{ isSlideEdit ? "Ubah" : "Tambah" }} Slide Show
            </h5>
          </div>
          <VForm @resp="submitFormSlider" method="post">
            <div class="card-body">
              <div class="row">
                <div class="col-12 info"></div>
                
                <div class="col-md-8">
                  <BoField
                    name="as_title"
                    v-model="row.as_title"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Menciptakan Generasi Islam yang Modern',
                      maxLength: '100',
                    }"
                    :label="'Judul'"
                    required=""
                  ></BoField>

                  <BoField name="as_desc" :label="'Deskripsi'" class="mb-0" mandatory>
                    <textarea
                      class="form-control"
                      rows="5"
                      name="as_desc"
                      v-model="sliderDesc"
                      :attr="{
                        maxLength: '100'
                      }"
                      required=""
                      placeholder="e.g. Kami membawa nilai-nilai kebaikan islam"
                    ></textarea>
                    <div error="slider_desc" class="m-0"></div>
                  </BoField>

                  <div class="row mt-2">
                    <div class="col-md-6">
                      <label>Warna Text Judul <span class="text-danger mr5">*</span></label>
                      
                      <div class="input-group mb-3 input-group-sm">
                        <input
                          @focus="showPicker('isPickerTitle')"
                          type="text"
                          required=""
                          class="form-control"
                          v-model="inputColorTitle"
                        />

                        <div class="input-group-append">
                          <chrome-picker v-if="isPickerTitle" v-model="pickerTitle" />

                          <span
                            @click="showPicker('isPickerTitle')"
                            class="input-group-text"
                            :style="`background-color: ${row.as_color_title || '#000000'} ; cursor: pointer;`"
                          ></span>

                        </div>

                      </div>
                    </div>

                    <div class="col-md-6">
                      <label>Warna Teks Deskripsi <span class="text-danger mr5">*</span></label>
                      
                      <div class="input-group mb-3 input-group-sm">
                        <input
                          @focus="showPicker('isPickerDesc')"
                          type="text"
                          required=""
                          class="form-control"
                          v-model="inputColorDescription"
                        />

                        <div class="input-group-append position-relative">
                          <chrome-picker v-if="isPickerDesc" v-model="pickerDesc" />

                          <span
                            @click="showPicker('isPickerDesc')"
                            class="input-group-text"
                            :style="`background-color: ${row.as_color_description || '#000000'} ; cursor: pointer;`"
                          ></span>

                        </div>

                      </div>
                    </div>
                  </div>

                  <div v-if="row.as_is_button">
                    <BoField :label="'Tampilkan tombol?'">
                      <radio
                        v-model="row.as_is_button"
                        option="Y"
                      >Ya</radio>
                      <radio 
                        v-model="row.as_is_button" 
                        option="N"
                      >Tidak</radio>
                    </BoField>
                  </div>

                  <div v-else>
                    <BoField :label="'Tampilkan tombol?'">
                      <radio
                        v-model="isSlideButton"
                        option="Y"
                      >Ya</radio>
                      <radio 
                        v-model="isSlideButton" 
                        option="N"
                      >Tidak</radio>
                    </BoField>
                  </div>
                  
                  <div v-if="isSlideComputed === 'Y'">
                    <BoField
                      name="as_button_name"
                      v-model="row.as_button_name"
                      :attr="{ type: 'text', placeholder: 'e.g. Daftar Sekarang' }"
                      required=""
                      :label="'Judul Tombol'"
                      mandatory
                    ></BoField>

                    <BoField
                      name="as_link"
                      v-model="row.as_link"
                      :attr="{
                        type: 'text',
                        placeholder: 'e.g. https://sdi-alhidayah.sch.id/about',
                      }"
                      :label="'Link'"
                      required=""
                      mandatory
                    ></BoField>
                    
                    <div class="col-md-4 px-0">
                      <BoField
                        name="as_link_target"
                        :label="'Tujuan Link'"
                        v-model="row.as_link_target"
                      >
                        <select v-model="row.as_link_target" class="form-control">
                          <option :value="'_self'">None</option>
                          <option :value="'_blank'">Blank</option>
                        </select>
                        <span slot="help">{{row.as_link_target === '_blank' ? 'Blank: Link akan dibuka pada tab yang berbeda' : 'None: Link akan dibuka pada tab browser yang sama'}}</span>
                      </BoField>
                    </div>
                  </div>

                  <BoField name="as_is_active">
                    <radio
                      name="as_is_active"
                      v-model="row.as_is_active"
                      option="Y"
                      :attr="validation('as_is_active')"
                    >
                      Active</radio
                    >
                    <radio
                      name="as_is_active"
                      v-model="row.as_is_active"
                      option="N"
                      >Inactive</radio
                    >
                  </BoField>
                </div>
                <div class="col-md-4">
                  <BoField name="as_img" :label="'Banner Desktop'" class="mb-0">
                    <Uploader
                      name="as_img"
                      type="slider"
                      uploadType="cropping"
                      :deleted="false"
                      :param="{ thumbnail: true }"
                      v-model="row.as_img"
                      :squarePreview="true"
                    ></Uploader>
                  </BoField>

                  <BoField
                    mandatory
                    name="as_alt_img"
                    v-model="row.as_alt_img"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Slider Image Desktop',
                    }"
                    required=""
                  ></BoField>

                  <BoField name="as_img_mobile" :label="'Banner Mobile'" class="mb-0">
                    <Uploader
                      name="as_img_mobile"
                      type="slidermobile"
                      title="Banner Mobile"
                      uploadType="cropping"
                      :deleted="false"
                      :param="{ thumbnail: true }"
                      v-model="row.as_img_mobile"
                      :squarePreview="true"
                    ></Uploader>
                  </BoField>

                  <BoField
                    mandatory
                    name="as_alt_img_mobile"
                    v-model="row.as_alt_img_mobile"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Slider Image Mobile',
                    }"
                    :label="'Alt Image Mobile'"
                    required=""
                  ></BoField>

                </div>
              </div>
              <div class="text-right">
                <button
                  v-if="isSlideEdit ? moduleRole('Edit') : moduleRole('Add')"
                  type="submit"
                  id="subSlideshow"
                  class="btn btn-rounded btn-info btn-loading"
                >
                  {{ isSlideEdit ? "Ubah" : "Tambah" }}
                </button>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title">Sekilas Tentang Kami</h5>
          
          <a
            v-if="moduleRole('Edit')"
            href="#"
            @click.prevent="editAbout"
            role="button"
            aria-expanded="false"
            aria-controls="clTentangKami"
            class="btn btn-info btn-rounded"
            ><i class="fas fa-edit m-r-10"></i>Perbarui Konten</a
          >
        </div>
      </div>

      <div class="card-body">
        <div class="sekilas-info col-12 mt-3"></div>

        <div v-if="moduleRole('Edit')">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              :checked="(about.as_val || {}).show === 'Y'"
              @click.prevent="toggleShow(1, $event)"
              id="sekilasCheck"
              type="checkbox"
            />

            <label class="custom-control-label" for="sekilasCheck"
              >Tampilkan section?</label
            >
          </div>
          <hr />
        </div>

        <div class="home-section-preview">
          <div v-if="!isEmptyObject(about.as_val)" class="row justify-content-between align-items-center">
            <div class="col-md-6">
              <div class="short-about">
                <div class="short-about__title mb-2">
                  <h2>{{ about.as_val.title }}</h2>
                  
                  <span class="short-about__title__semi">{{
                    about.as_val.postTitle
                  }}</span>
                </div>

                <div class="short-about__desc" v-html="about.as_val.desc"></div>
              </div>
            </div>

            <div class="col-md-5">
              <img :src="uploader(about.as_val.image)" alt="" />
            </div>
          </div>
        </div>

        <div
          v-if="moduleRole('Edit')"
          class="card border collapse my-2"
          id="clTentangKami"
        >
          <div class="card-header">
            <h5 class="card-title">Perbarui Sekilas Tentang Kami</h5>
          </div>

          <div class="card-body">
            <VForm @resp="submitAbout" id="collapseAbout" method="post">
              <div v-if="!isEmptyObject(about.as_val)" class="row">
                <div class="about-info col-12"></div>

                <div class="col-md-8">
                  <BoField
                    name="title"
                    :label="'Judul'"
                    :attr="{
                      placeholder: 'e.g. Allah akan meninggikan orang-orang yang beriman...',
                    }"
                    v-model="about.as_val.title"
                    mandatory
                  >
                  </BoField>

                  <BoField
                    name="postTitle"
                    :label="'Sub Judul'"
                    :attr="{
                      placeholder: 'e.g. - QS. Al-Mujadadah: 11'
                    }"
                    v-model="about.as_val.postTitle"
                  ></BoField>

                  <BoField name="desc" mandatory :label="'Deskripsi'">
                    <CKEditor
                      class="form-control"
                      minlength="10"
                      name="desc"
                      v-model="aboutDesc"
                      v-bind="validation('desc')"
                      required=""
                    ></CKEditor>
                    <div error="about_desc"></div>
                  </BoField>
                </div>

                <div class="col-md-4">
                  <BoField name="image" mandatory :label="'Image'" class="mb-0">
                    <Uploader
                      name="image"
                      :param="{ thumbnail: true }"
                      type="about"
                      uploadType="cropping"
                      :deleted="false"
                      v-model="about.as_val.image"
                    ></Uploader>
                  </BoField>

                  <BoField
                    mandatory
                    name="alt_img"
                    v-model="about.as_val.alt_img"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Sekilas Tentang Kami'
                    }"
                    :label="'Alt Image'"
                  ></BoField>

                  <div id="errorAboutAlt"></div>
                </div>
                
                <div class="col-12 mt-3 text-right">
                  <button
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title">Prestasi Kami</h5>

            <router-link :to="{ name: 'BoPrestasi' }">
              Daftar prestasi
              <i class="ti-arrow-right ml-1 align-middle"></i>
            </router-link>
        </div>
      </div>

      <div class="card-body ">
        <div v-if="moduleRole('Edit')">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              :checked="(prestasi.as_val || {}).show === 'Y'"
              @click.prevent="toggleShow(2, $event)"
              id="prestasiCheck"
              type="checkbox"
            />

            <label class="custom-control-label" for="prestasiCheck"
              >Tampilkan section?</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title">Nilai-nilai Kami</h5>

          <a
            v-if="moduleRole('Add')"
            href="javascript:;"
            @click.prevent="addNilai"
            class="btn btn-info btn-rounded"
          >
            <i class="fas fa-plus-circle m-r-10"></i>Tambah Nilai-nilai Kami
          </a>
        </div>
      </div>

      <div class="card-body">
        <div v-if="moduleRole('Edit')">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              :checked="(nilai.as_val || {}).show === 'Y'"
              @click.prevent="toggleShow(72, $event)"
              id="nilaiNilaiCheck"
              type="checkbox"
            />

            <label class="custom-control-label" for="nilaiNilaiCheck"
              >Tampilkan section?</label
            >
          </div>
          <hr />
        </div>
        <draggable
          v-if="moduleRole('Edit')"
          v-model="nilai.data"
          class="row gutter-20"
          @end="endDragNilai(nilai.data)"
        >
          <div
            style="cursor:move;"
            class="col-md-4 col-lg-6"
            :id="'dat' + v.an_id"
            v-for="(v, k) in nilai.data"
            :key="k"
          >
            <div class="card border mb-0 h-100">
              <div class="card-body">
                <div class="bullet-cta">
                  <a
                    v-if="moduleRole('Edit')"
                    data-toggle="collapse"
                    @click="editNilai(v)"
                    href="javascript:;"
                    class="bullet_edit"
                    v-tooltip="'Ubah'"
                    ><i class="ti-marker-alt"></i
                  ></a>

                  <a
                    v-if="moduleRole('Edit')"
                    href="javascript:;"
                    class="bullet_change_status bg-warning"
                    @click="
                      changeStatusDynamic(
                        v,
                        'an_is_active',
                        'an_id',
                        'AppNilai',
                        undefined,
                        `collapseNilai${isEdit ? v.an_id : ''}`
                      )
                    "
                    v-tooltip="'Ubah Status'"
                    ><i class="ti-settings"></i
                  ></a>

                  <a
                    v-if="moduleRole('Delete')"
                    href="javascript:;"
                    @click="deleteItemId(v.an_id, 'dat' + v.an_id, `collapseNilai${isEdit ? v.an_id : ''}`)"
                    class="bullet_delete"
                    v-tooltip="'Hapus'"
                    ><i class="far fa-trash-alt"></i
                  ></a>
                </div>

                <span class="label label-success" v-if="v.an_is_active == 'Y'"
                  >Active</span
                >

                <span class="label label-danger" v-else>Inactive</span>

                <div class="value">
                  <div :class="`value-circle ${v.an_bg}`">
                    <div class="value-circle__content">
                      <img
                        :src="uploader(v.an_img)"
                        class="value-circle__img"
                      />
                    </div>
                  </div>
                  
                  <div class="value-content">
                    <h3 class="value-content__title">{{ v.an_title }}</h3>

                    <p class="value-content__desc">{{ v.an_desc }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <div v-else class="row gutter-20" @end="endDragNilai(nilai.data)">
          <div
            class="col-md-4 col-lg-6"
            :id="'dat' + v.an_id"
            v-for="(v, k) in nilai.data"
            :key="k"
          >
            <div class="card border mb-0 h-100">
              <div class="card-body">
                <div class="bullet-cta">
                  <a
                    v-if="moduleRole('Edit')"
                    data-toggle="collapse"
                    @click="editNilai(v)"
                    href="javascript:;"
                    class="bullet_edit"
                    v-tooltip="'Ubah'"
                    ><i class="ti-marker-alt"></i
                  ></a>

                  <a
                    v-if="moduleRole('Edit')"
                    href="javascript:;"
                    class="bullet_change_status bg-warning"
                    @click="
                      changeStatusDynamic(
                        v,
                        'an_is_active',
                        'an_id',
                        'AppNilai'
                      )
                    "
                    v-tooltip="'Ubah Status'"
                    ><i class="ti-settings"></i
                  ></a>

                  <a
                    v-if="moduleRole('Delete')"
                    href="javascript:;"
                    @click="deleteItemId(v.an_id, 'dat' + v.an_id, 'collapseNilai')"
                    class="bullet_delete"
                    v-tooltip="'Hapus'"
                    ><i class="far fa-trash-alt"></i
                  ></a>
                </div>

                <span class="label label-success" v-if="v.an_is_active == 'Y'"
                  >Active</span
                >

                <span class="label label-danger" v-else>Inactive</span>

                <div class="value">
                  <div :class="`value-circle ${v.an_bg}`">
                    <div class="value-circle__content">
                      <img
                        :src="uploader(v.an_img)"
                        class="value-circle__img"
                      />
                    </div>
                  </div>

                  <div class="value-content">
                    <h3 class="value-content__title">{{ v.an_title }}</h3>

                    <p class="value-content__desc">{{ v.an_desc }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="isNilaiEdit ? moduleRole('Edit') : moduleRole('Add')"
          class="card border collapse my-2 c-nilai"
          :id="isNilaiEdit ? 'collapseNilai' + input.an_id : 'collapseNilai'"
        >
          <div class="card-header">
            <h5 class="card-title">
              {{ input.an_id ? 'Ubah' : 'Tambah'}} Nilai-nilai kami
            </h5>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-4 mb-3">
                <BoField name="an_img" class="mb-0" mandatory>
                  <Uploader
                    name="an_img"
                    :param="{ thumbnail: true }"
                    :required="true"
                    type="goodness"
                    :deleted="false"
                    uploadType="cropping"
                    v-model="input.an_img"
                    :bgGrey="true"
                    :alt="input.an_alt_img"
                  ></Uploader>
                </BoField>
                
                <BoField
                      mandatory
                      name="an_alt_img"
                      v-model="input.an_alt_img"
                      :attr="{
                        type: 'text',
                        placeholder: 'e.g. Religius',
                        minlength: '3'
                      }"
                      :label="'Alt Image'"
                    ></BoField>
              </div>

              <div class="col-md-8 mb-3">
                <VForm @resp="submitGoodness" method="post">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="panel_group">
                        <div class="panel_heading">Nilai-Nilai Kami</div>

                        <div class="panel_body">
                          <div class="row">
                            <div class="col-12 col-info"></div>

                            <div class="col-md-6">
                              <BoField
                                name="an_title"
                                v-model="input.an_title"
                                :attr="{
                                  maxlength: '100',
                                  minlength: '3',
                                  required: 'required',
                                  placeholder: 'e.g. Religius'
                                }"
                                :label="'Judul'"
                                mandatory
                              ></BoField>

                              <BoField name="an_bg" 
                                  :label="'Warna Background'" 
                                  mandatory>
                                <select
                                  v-model="input.an_bg"
                                  class="form-control"
                                >
                                  <option value="bg-yellow">Yellow</option>
                                  <option value="bg-green">Green</option>
                                  <option value="bg-blue">Blue</option>
                                  <option value="bg-red">Red</option>
                                </select>
                              </BoField>

                            </div>

                            <div class="col-md-12">
                              <BoField name="an_desc" :label="'Deskripsi'" mandatory>
                                <textarea
                                  class="form-control"
                                  minlength="10"
                                  name="an_desc"
                                  v-model="nilaiDesc"
                                  v-bind="validation('an_desc')"
                                  style="height: 200px !important"
                                  placeholder="e.g. Mendidik peserta didik agar menjadi anak yang menjalankan ajaran Islam"
                                ></textarea>
                                <div error="nilai_desc"></div>
                              </BoField>
                            </div>

                            <div class="col-sm-4">
                              <BoField name="an_is_active">
                                <radio
                                  name="an_is_active"
                                  v-model="input.an_is_active"
                                  option="Y"
                                  :attr="validation('an_is_active')"
                                  >Active</radio
                                >
                                <radio
                                  name="an_is_active"
                                  v-model="input.an_is_active"
                                  option="N"
                                  >Inactive</radio
                                >
                              </BoField>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-3 text-right">
                      <button
                        v-if="
                          isNilaiEdit ? moduleRole('Edit') : moduleRole('Add')
                        "
                        type="submit"
                        id="subNilai"
                        class="btn btn-rounded btn-loading btn-info"
                      >
                        {{ isNilaiEdit ? "Ubah" : "Tambah" }}
                      </button>
                    </div>
                  </div>
                </VForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title">Kurikulum</h5>

          <a
            v-if="moduleRole('Edit')"
            href="#clCurriculumContent"
            @click.prevent="scrollTo('#clCurriculumContent')"
            class="btn btn-info btn-rounded"
            role="button"
            aria-expanded="false" aria-controls="clCurriculumContent"
          >
            <i class="fas fa-edit m-r-10"></i>Perbarui Konten
          </a>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div v-if="moduleRole('Edit')">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                :checked="(kurikulum.as_val || {}).show === 'Y'"
                @click.prevent="toggleShow(3, $event)"
                id="kurikulumCheck"
                type="checkbox"
              />

              <label class="custom-control-label" for="kurikulumCheck"
                >Tampilkan section?</label
              >
            </div>

            <hr />
          </div>
        </div>
        <div class="home-section-preview">
          <div v-if="!isEmptyObject(kurikulum.as_val)" class="row align-items-center">
            <div class="col-md-6">
              <div class="curr-preview">
                <div class="islamic-explanation">
                  <div class="heading-block border-0 mb-sm">
                    <h2
                      class="islamic-explanation__title"
                      v-html="kurikulum.as_val.title"
                    ></h2>
                  </div>

                  <div v-html="kurikulum.as_val.desc_content"></div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row gutter-20">
                <div class="col-md-6">
                  <div class="crcl-media position-relative">
                    <div class="bullet-cta">
                      <a
                        v-if="moduleRole('Edit')"
                        @click.prevent="
                          editCrlImg(kurikulum.as_val.image1, kurikulum.as_val.alt_image1, 'Image #1', 'alt_image1')
                        "
                        class="bullet_edit"
                        v-tooltip="'Ubah'"
                        ><i class="ti-marker-alt"></i
                      ></a>
                    </div>
                    
                    <img alt="" :src="uploader(kurikulum.as_val.image1)" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="crcl-media position-relative">
                    <div class="bullet-cta">
                      <a
                        v-if="moduleRole('Edit')"
                        @click.prevent="
                          editCrlImg(kurikulum.as_val.image2, kurikulum.as_val.alt_image2, 'Image #2', 'alt_image2')
                        "
                        class="bullet_edit"
                        v-tooltip="'Ubah'"
                        ><i class="ti-marker-alt"></i
                      ></a>
                    </div>

                    <img alt="" :src="uploader(kurikulum.as_val.image2)" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="crcl-media position-relative">
                    <div class="bullet-cta">
                      <a
                        v-if="moduleRole('Edit')"
                        @click.prevent="
                          editCrlImg(kurikulum.as_val.image3, kurikulum.as_val.alt_image3, 'Image #3', 'alt_image3')
                        "
                        class="bullet_edit"
                        v-tooltip="'Ubah'"
                        ><i class="ti-marker-alt"></i
                      ></a>
                    </div>

                    <img alt="" :src="uploader(kurikulum.as_val.image3)" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="crcl-media position-relative">
                    <div class="bullet-cta">
                      <a
                        v-if="moduleRole('Edit')"
                        @click.prevent="
                          editCrlImg(kurikulum.as_val.image4, kurikulum.as_val.alt_image4, 'Image #4', 'alt_image4')
                        "
                        class="bullet_edit"
                        v-tooltip="'Ubah'"
                        ><i class="ti-marker-alt"></i
                      ></a>
                    </div>

                    <img alt="" :src="uploader(kurikulum.as_val.image4)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="modalCurriculumImg"
          v-if="moduleRole('Edit')"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Kurikulum</h5>
                
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="ti-close"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="kr-img-info"></div>

                <VForm @resp="submitKurikulumImage" method="post">
                  <BoField
                    name="image"
                    mandatory
                    :label="kurikulumImage.curLabelInput"
                    class="mb-0"
                  >
                    <Uploader
                      name="image"
                      :param="{ thumbnail: true }"
                      type="kurikulum"
                      :required="true"
                      uploadType="cropping"
                      :deleted="false"
                      v-model="kurikulumImage.currImg"
                      :alt="kurikulumImage.currImg"
                    ></Uploader>
                  </BoField>

                  <div error="currImg"></div>

                  <BoField
                    mandatory
                    name="krAltImage"
                    v-model="kurikulumImage.currAltImg"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Kurikulum Tilawah'
                    }"
                    :label="'Alt Image'"
                    required="required"
                  ></BoField>
                  
                  <div class="col-12 mt-3 text-right">
                    <button
                      type="submit"
                      class="btn btn-rounded btn-loading btn-info"
                    >
                      Perbarui
                    </button>
                  </div>
                </VForm>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="moduleRole('Edit')"
          class="card border collapse my-2"
          id="clCurriculumContent"
        >
          <div class="card-header">
            <h5 class="card-title">Perbarui konten kurikulum</h5>
          </div>

          <div class="card-body">
            <VForm @resp="submitKurikulum" method="post">
              <div class="row">
                <div class="kurikulum-info col-12 mt-3"></div>

                <div class="col-md-6">
                  <BoField
                    name="titleKurikulum"
                    :attr="{
                        placeholder: 'e.g. Tilawah'
                      }"
                    :label="'Judul'"
                    v-model="(kurikulum.as_val || {}).title"
                    mandatory
                  >
                  </BoField>
                </div>

                <div class="col-md-6">

                <BoField name="desc_content" mandatory :label="'Deskripsi'">
                    <CKEditor
                      class="form-control"
                      name="desc_content"
                      v-model="kurikulumDesc"
                    ></CKEditor>
                    <div error="kurikulum_desc"></div>
                  </BoField>
                </div>

                <div class="col-12 text-right" style="margin-top: -20px">
                  <button
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title">Fasilitas Sekolah</h5>

          <router-link :to="{ name: 'BoFasilitas' }"
            >Daftar Fasilitas
            <i class="ti-arrow-right ml-1 align-middle"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div v-if="moduleRole('Edit')">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              :checked="(fasilitas.as_val || {}).show === 'Y'"
              @click.prevent="toggleShow(73, $event)"
              id="fasilitasCheck"
              type="checkbox"
            />

            <label class="custom-control-label" for="fasilitasCheck"
              >Tampilkan section?</label
            >
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Testimoni Orang Tua Murid</h5>

              <router-link :to="{ name: 'BoTestimoni' }"
                >Daftar Testimoni
                <i class="ti-arrow-right ml-1 align-middle"></i
              ></router-link>
            </div>
          </div>

          <div class="card-body ">
            <div v-if="moduleRole('Edit')">
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    :checked="(testi.as_val || {}).show === 'Y'"
                    @click.prevent="toggleShow(4, $event)"
                    id="testiCheck"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="testiCheck"
                    >Tampilkan section?</label
                  >
                </div>

                <hr />
              </div>

              <div class="testi-info"></div>
            </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Berita & Artikel</h5>

              <router-link :to="{ name: 'BoNews' }"
                >Daftar Berita & Artikel
                <i class="ti-arrow-right ml-1 align-middle"></i
              ></router-link>
            </div>
          </div>

          <div class="card-body ">
          <div v-if="moduleRole('Edit')">
              <div class="custom-control custom-checkbox">
                <input
                  class="custom-control-input"
                  :checked="(article.as_val || {}).show === 'Y'"
                  @click.prevent="toggleShow(5, $event)"
                  id="articleCheck"
                  type="checkbox"
                />

                <label class="custom-control-label" for="articleCheck"
                  >Tampilkan section?</label
                >
              </div>
              <hr />
            </div>
            
            <div class="article-info"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .vc-chrome {
    position: absolute;
    right: 0;
    z-index: 98;
  }
  .picker-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 97;
  }
</style>